import moment from "moment";
import { TOKEN } from "../../utils/Storage";
import * as jose from 'jose'

const useLegacySupport:any = (props:any) => {
    const urlParams = new URLSearchParams(window.location.search).get("coreKey");
    const isSummaryOneView =(window.location.pathname === "/caseSummaryOneView" ? true : false);
    
    // Utility function to safely parse JSON
    const safeParseJSON = (value: string | null): any => {
        try {
            if (value === null) {
                return null;
            }
            
            let token:any = JSON.parse(value);
            let jToken:any = jose.decodeJwt(token.token);
            let expTime:any = moment(jToken.exp * 1000).format("YYYY-MM-DD HH:mm:ss")
            
            return { ...token, isLegacy: token?.provider !== "msal", isExpired: moment(expTime).isBefore(moment().format("YYYY-MM-DD HH:mm:ss")) }; ;
        } catch (error) {
            console.error("Failed to parse token:", error);
            return null;
        }
    };

    const token= urlParams || safeParseJSON(localStorage.getItem(TOKEN)) || null;
    const isLegacySupported = (urlParams !== null && urlParams.length > 0) || false;

    return { isLegacySupported, token, isSummaryOneView }
}

export default useLegacySupport;