import { t } from "i18next";
import { parseDataByType } from "../../../utils";
import { toast } from "react-toastify";


const processDataForKendoGrid:any = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.gridRows.length > 0) {
            resData.gridRows.forEach((res: any, index: number) => {
                let obj: any = {};

                res.values.forEach((tdRes: any, tdIndex: number) => {
                    const colDetails = resData.gridColumns[tdIndex];
                    obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                });

                processedData.push(obj);
            })
        }

        const tmp = {
            gridColumns: resData.gridColumns,
            gridRows: processedData,
            emptyMessage: "No records were found..",
            rowCount: processedData.length
        }
        
        return tmp;

    } catch (e) {
        console.log(e)
    }
}


const processDataForKendoGridList:any = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.tableData.caseSummaryDataRows.length > 0) {
            resData.tableData.caseSummaryDataRows.forEach((res: any, index: number) => {
                let obj: any = {};

                res.values.forEach((tdRes: any, tdIndex: number) => {
                    const colDetails = resData.tableData.caseSummaryDataColumns[tdIndex];
                    obj[colDetails.name.trim()] = parseDataByType(tdRes, '');
                });

                processedData.push(obj);
            })
        }


        const processedColumns:any = [];    
        if (resData.tableData.caseSummaryDataColumns.length > 0) {
            resData.tableData.caseSummaryDataColumns.forEach((res: any, index: number) => {
                let obj: any = res;
                obj['isVisible'] = obj.visible === "Y"
                processedColumns.push(obj);
            })
        }

        const tmp = {
            gridColumns: processedColumns,
            gridRows: processedData,
            emptyMessage: "No records were found..",
            rowCount: processedData.length,
            summary: resData.tableData.summary
        }

        return tmp

    } catch (e) {
        console.log(e)
    }
}

const processDataForKendoGridChildData:any = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.caseSummaryDataRows.length > 0) {
            resData.caseSummaryDataRows.forEach((res: any, index: number) => {
                let obj: any = {};
                obj["id"] = index;

                res.values.forEach((tdRes: any, tdIndex: number) => {

                    const colDetails = resData.caseSummaryDataColumns[tdIndex];
                    if (colDetails !== undefined) {
                        obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                    } else {
                        toast.info(t("Error mapping column details. Please contact support."));
                    }

                });

                processedData.push(obj);
            })
        }

        return { ...resData, gridRows: processedData }
    } catch (e) {
        console.log(e)
    }
}


const processDataDetailsForKendoGrid:any = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.gridRows.length > 0) {
            resData.gridRows.forEach((res: any, index: number) => {
                let obj: any = {};
                
                resData.gridColumns.map((col: any) => {
                    if (res[col.name]) {
                        obj[col.name.trim()] = parseDataByType(res[col.name], col.dataType);
                    }
                });
                
                processedData.push(obj);
            });
        }

        const tmp = {
            gridColumns: resData.gridColumns,
            gridRows: processedData,
            emptyMessage: "No records were found..",
            rowCount: processedData.length,
            summary: resData.summary
        }

        return tmp;
    } catch (e) {
        console.log(e)
    }
}



export default { processDataForKendoGrid, processDataForKendoGridList, processDataForKendoGridChildData, processDataDetailsForKendoGrid }