import { useEffect, useState } from "react";
import {
  AuthWrapperCM as AuthWrapper,
  Button,
  DropDown,
  useLegacySupport,
  TextField,
  Loader,
} from "../../components";
import TopHeader from "../dashboard/component/topHeader";
import { t } from "i18next";
import { closeIcon, map_white } from "../../assets/icons";
// import { APIProvider, Marker, Map } from '@vis.gl/react-google-maps';
import { Toggle } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  communityAssetsConfig,
  fetchCommunityAssetsList,
  fetchMapCoOrdinates,
  makeShift,
  resetCommunityAssetsConfig,
  resetFetchCommunityAssetsList,
  resetFetchMapCoOrdinates,
  resetMakeShift,
} from "./store/actions";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getData, TOKEN } from "../../utils/Storage";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";

import "react-leaflet-markercluster/dist/styles.min.css";
import KendoGrid from "../../components/containers/kendo-grid";
import communityAssetsService from "./services/community-assets-service";

const listData = [
  { title: "List", value: "list" },
  { title: "Map", value: "map" },
];

const CommunityAssets = (props: any) => {
  const dispatch = useDispatch();

  const [selectedDSP, setSelectedDSP] = useState<any>(null);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [isCaseSelectedSearch, setIsCaseSelectedSearch] =
    useState<boolean>(false);
  const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
  const [mapCoOrdinates, setMapCoOrdinates] = useState<any>([]);
  const [selectedListType, setSelectedListType] = useState<any>(null);
  const [gridControlId, setGridControlId] = useState(null);
  const [toolbar, setToolbar] = useState<any>(null);
  const [communityAssetsList, setCommunityAssetsList] = useState<any>(null);
  const [mapCenter, setMapCenter] = useState<any>([51.5215237, -0.1456575]);
  const [selectedCase, setSelectedCase] = useState<any>(null);

  const communityAssetsConfigData = useSelector(
    (state: any) => state.communityAssetsConfig
  );
  const communityAssetsData = useSelector(
    (state: any) => state.communityAssets
  );
  const communityAssetsListData = useSelector(
    (state: any) => state.communityAssetsList
  );

  const makeShiftData = useSelector((state: any) => state.makeShift);

  const { dsp, group_reference, postcode } = props || {};

  // Custom blue icon
  const blueIcon = new Leaflet.Icon({
    iconUrl:
      "https://mapmarker.io/api/v2/font-awesome/v5/pin?icon=fa-circle&size=30&color=FFF&background=0080FF&hoffset=0&voffset=0",
  });

  const greenTcon = new Leaflet.Icon({
    iconUrl:
      "https://mapmarker.io/api/v2/font-awesome/v5/pin?icon=fa-circle&size=30&color=FFF&background=008000&hoffset=0&voffset=0",
  });

  useEffect(() => {
    let dsp = props?.dsp;
    setSelectedDSP(dsp);
    try {
      getData(TOKEN).then((res: any) => {
        let token;

        if (res?.provider === "msal") {
          token = { isLegacy: false, token: res.token };
        } else {
          token = { isLegacy: true, token: res.token };
        }
        setToken(token);

        dispatch(
          communityAssetsConfig({
            token: token,
            dspId: dsp,
          })
        );
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (isCaseSelectedSearch) {
      fetchCommunityAssetsTable();
    }
  }, [isCaseSelectedSearch]);

  useEffect(() => {
    if (makeShiftData.isSuccess && makeShiftData.data !== null) {
      toast.success("Saved successfully");
      dispatch(resetMakeShift());
    }else if (makeShiftData.isError) {
      toast.error(makeShiftData.data.error.message);
      dispatch(resetMakeShift());
    }
  }, [makeShiftData])

  useEffect(() => {
    if (communityAssetsData.isSuccess && communityAssetsData.data !== null) {
      const mapData = communityAssetsData.data;

      if (mapData[0].latitude !== null && mapData[0].longitude !== null) {
        setMapCenter([
          parseFloat(mapData[0].latitude),
          parseFloat(mapData[0].longitude),
        ]);
      }

      const tempArray: any = [];
      mapData.map((item: any) => {
        const key = `LAT${item.latitude}-LONG${item.longitude}`;
        const hasObject = tempArray[key];

        if (hasObject !== undefined) {
          const mapInfo = item.mapInfo;

          if (item.mapInfo !== null) {
            const oldMapInfo = tempArray[key].mapInfo;
            tempArray[key].mapInfo = [...oldMapInfo, mapInfo, "<br /> <br />"];
          }
        } else {
          tempArray[key] = {
            id: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
            mapInfo: item.mapInfo === null ? [] : [item.mapInfo],
          };
        }
      });

      setMapCoOrdinates(Object.values(tempArray));

      dispatch(resetFetchMapCoOrdinates());
    } else if (communityAssetsData.isError) {
      toast.error(communityAssetsData.data.error.message);
      dispatch(resetFetchMapCoOrdinates());
    }

    if (
      communityAssetsConfigData.isSuccess &&
      communityAssetsConfigData.data !== null
    ) {
      let data = communityAssetsConfigData.data.popupForm;
      setGridControlId(data.dataGrid.dataGridId);
      setToolbar(data.dataGrid.toolbar.controls);

      if (postcode !== undefined && postcode !== "" && postcode.length > 0) {
        setSelectedFilters({ Postcode: postcode });
        setIsCaseSelectedSearch(true);
      } else if (postcode !== undefined && postcode?.length === 0) {
        toast.error("Selected case dose not have valid postcode");
      }

      dispatch(resetCommunityAssetsConfig());
    } else if (communityAssetsConfigData.isError) {
      toast.error(communityAssetsConfigData.data.error.message);
      dispatch(resetCommunityAssetsConfig());
    }

    if (
      communityAssetsListData.isSuccess &&
      communityAssetsListData.data !== null
    ) {
      let data = communityAssetsListData.data;
      const gridData = communityAssetsService.processDataForKendoGrid(data);

      setCommunityAssetsList(gridData);

      dispatch(resetFetchCommunityAssetsList());
    } else if (communityAssetsListData.isError) {
      toast.error(communityAssetsListData.data.error.message);
      dispatch(resetFetchCommunityAssetsList());
    }
  }, [communityAssetsData, communityAssetsConfigData, communityAssetsListData]);

  const fetchCommunityAssetsCoordinatess = () => {
    try {
      dispatch(
        fetchMapCoOrdinates({
          token: token,
          param: {
            ...selectedFilters,
            dspId: dsp,
            controlId: gridControlId,
          },
        })
      );
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const fetchCommunityAssetsTable = () => {
    try {
      dispatch(
        fetchCommunityAssetsList({
          token: token,
          param: {
            ...selectedFilters,
            dspId: dsp,
            controlId: gridControlId,
          },
        })
      );
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const __setFilterValueText = (e: any, controlId: number) => {
    console.log(e, controlId);
      setSelectedFilters((prevState: any) => ({
        ...prevState,
        [controlId]: e.value,
      }));
  };

  const __setFilterValue = (e: any, controlId: number) => {  
    if (e.length > 0) {
      let value: any = "";

      if (e[0].value !== undefined) {
        value = e[0].value;
      } else {
        value = e.map((val: any) => val.value).join(", ");
      }

      setSelectedFilters((prevState: any) => ({
        ...prevState,
        [controlId]: value
      }));
    } else {
      setSelectedFilters([]);
    }
  };

  const _onRefreshButton = (e: any) => {
    e.preventDefault();
    __callAPI(selectedListType);
  };

  const __callAPI = (type: any) => {
    if (
      typeof selectedFilters === "object" &&
      (selectedFilters?.Postcode !== undefined ||
        selectedFilters?.Ward !== undefined)
    ) {
      if (type !== null && type?.value === "map") {
        fetchCommunityAssetsCoordinatess();
      } else {
        fetchCommunityAssetsTable();
      }
    } else {
      toast.error("Please select a valid Postcode or Ward");
    }
  };

  const __toggleListType = (item: any) => {
    setSelectedListType(item);

    console.log("selectedListType", item);
    __callAPI(item);
  };

  const __buttonAction = (name: string) => {
    if (name === "clearButton") {
      setSelectedFilters([]);
    }
  };


  const __makeShift = () => {
    if (selectedCase === null) {
      toast.error("Please select a case to make a shift");
      return;
    }

    dispatch(makeShift({
      token: token,
      param : {
        dspId: selectedDSP,
        oneViewReference: group_reference,
        scvId: props.selectedSCVId,
        controlId: toolbar[7].associatedControlId,
        idArray: selectedCase.id
      },
      url: toolbar[7].addRecordApiEndPoint.endpointUrl.replace("/api", "")
    }))
  } 

  const _renderFilterToolbar = () => {
    if (communityAssetsConfigData.isFetching && toolbar === null) {
      return <Loader />;
    }

    return (
      <div className="community-assets-toolbar" key={11}>
        <div className="toolbar-wrapper">
          <form
            method="get"
            className="flex-grow-1"
            onSubmit={_onRefreshButton}
          >
            <div className="flex-grow-1 d-flex">
              {toolbar !== null &&
                toolbar.map((field: any) => {
                  switch (field.type) {
                    case "selectpicker":

                      let selectedValue = '';

                      if (selectedFilters[field.name] !== undefined) {
                        selectedValue = selectedFilters[field.name];
                      } else {
                        selectedValue = field.defaultSelectedValue !== undefined ? field.defaultSelectedValue : undefined;
                      }
                      
                      return (
                        <div
                          className="toolbar-container flex-fill"
                          id={"d" + field.name}
                          key={field.name}
                        >
                          <DropDown
                            label={field.label}
                            labelExternal={true}
                            name={"name"}
                            isMultiSelectPickList={false}
                            data={field.picklistValues.map((item: any) => {
                              return {
                                id: item.textToDisplay,
                                title: item.textToDisplay,
                                value: item.valueToStore,
                              };
                            })}
                            defaultSelected={selectedValue}
                            onChange={(e) => __setFilterValue(e, field.name)}
                          />
                        </div>
                      );
                    case "text":
                      return (
                        <div
                          className="toolbar-container flex-fill"
                          key={field.name}
                        >
                          <TextField
                            label={field.label}
                            labelExternal={true}
                            type="text"
                            defaultSelected={selectedFilters[field.name] !== undefined ? selectedFilters[field.name] : ""}
                            placeholder={field.label}
                            onChange={(e: any) => __setFilterValueText(e, field.name)
                            }
                          />
                        </div>
                      );
                    case "button":
                      return (
                        <div
                          className="toolbar-button-container"
                          key={field.name}
                        >
                          <Button
                            // style={{ backgroundColor: "#5464B0", border: "none", color: "#FFFFFF", padding: "0.625rem 1.188rem", fontWeight: "700", borderRadius: "0.625rem" }}
                            title={field.label}
                            disabled={
                              communityAssetsData.isFetching ||
                              communityAssetsListData.isFetching
                            }
                            className={
                              field.name !== "clearButton"
                                ? "btn-cta blue-background"
                                : "btn-cta transparent-background"
                            }
                            type={
                              field.name === "clearButton" ? "reset" : "submit"
                            }
                            onClick={() => __buttonAction(field.name)}
                          />
                        </div>
                      );

                    default:
                  }
                })}
            </div>
          </form>

          {toolbar !== null && toolbar.map((field: any) => {
            if (field.type === "actionButton" && field.visible) {
                return <div className="flex-shrink-1 toolbar-toggle-wrapper">
                  <div
                    className="toggle-wrapper">
                    <Button
                      title={field.label}
                      // disabled={ communityAssetsData.isFetching || communityAssetsListData.isFetching }
                      className={"btn-cta blue-background"}
                      type={"button"}
                      onClick={__makeShift}
                    />
                  </div>
                </div>
              }
            })
}
          {/* tab button action incommunity assets screen */}
          <div className="flex-shrink-1 toolbar-toggle-wrapper">
            <Toggle
              selected={
                selectedListType !== null ? selectedListType : listData[0]
              }
              list={listData}
              isDisabled={
                communityAssetsData.isFetching ||
                communityAssetsConfigData.isFetching ||
                communityAssetsListData.isFetching
              }
              onClick={(item: any) => __toggleListType(item)}
            />
          </div>
        </div>
      </div>
    );
  };

  {
    /* Map action buttons for the community Assets */
  }
  const _renderMapFunc = () => {
    if (communityAssetsData.isFetching) {
      return <Loader />;
    }

    if (mapCoOrdinates.length === 0) {
      return null;
    }

    return (
      <MapContainer
        center={mapCenter}
        zoom={13}
        style={{ height: window.innerHeight - 200, width: "100%" }}
      >
        <TileLayer
          noWrap={true}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {mapCoOrdinates.map((item: any, i: any) => {
          if (item.latitude === null || item.longitude === null) return null;
          let latlong: any = [item.latitude, item.longitude];

          return (
            <Marker
              key={item.id}
              position={latlong}
              icon={item.id === 0 ? greenTcon : blueIcon}
            >
              {item.mapInfo && (
                <Popup>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.mapInfo }}
                    style={{ maxHeight: 300, overflow: "scroll" }}
                  />
                </Popup>
              )}
            </Marker>
          );
        })}
      </MapContainer>
    );
  };

  {
    /* List action buttons for the community Assets */
  }
  const _renderListFunc = () => {
    if (communityAssetsListData.isFetching) {
      return <Loader />;
    }

    if (communityAssetsList !== null) {
      return (
        <div className="grid-wrapper">

          <KendoGrid
            gridData={communityAssetsList}
            isExport={false}

            selectedField={
              props.isReferral ? "SELECTED_FIELD" : ""
            }
            onSelectionChange={setSelectedCase}
            isSelectable={props.isReferral ? {
              mode: "single"
            } : false}

            isFilterable={false}
            isPaginated={true}
            expandField=""
            onExpand={(e: any) => { }}
            onHiddenCount={(e: any) => { }}
          // custom action layout for edit, details etc
          // isCustomAction: boolean;
          />
        </div>
      );
    }
  };

  // function to render the section that incudes the filter and the serach bar
  const __renderMainSection = () => {
    return (
      <div>
        {/* dropdown option for filtering the case  */}
        <div className="filter-content pos-relative">
          <div className="filter-header">
            <div className="">
              <h3>
                <img
                  src={map_white}
                  alt={t("Community Assets")}
                  style={{ width: 30, paddingRight: 10 }}
                  className=""
                />

                {t("Community Assets")}
              </h3>
            </div>

            <div className="d-flex">
              {isCaseSelectedSearch ? (
                <div className="px-4">
                  <h6 className="m-0">
                    <span className="px-3">View Assets For</span>
                    <button className="btn btn-primary btn-border">
                      ID {group_reference}
                    </button>
                  </h6>
                </div>
              ) : null}
              {/* <div>
                <h6 className="m-0">
                  <span className="px-3">Switch To</span>
                  <button
                    className="btn btn-primary btn-border"
                    onClick={() => {
                      setSelectedFilters([]);
                      setIsCaseSelectedSearch(false);
                      setSelectedFilters({ Postcode: "" });
                    }}
                  >New Search</button>
                </h6>
              </div> */}
            </div>

            <div className="button-list">
              <div className="space"></div>
              <button
                className="btn btn-transparent"
                onClick={() => props.onClose()}
              >
                <img src={closeIcon} alt="close" />
              </button>
              {/* <div className="space"></div> */}
            </div>
          </div>
          <div className="content" style={{ height: "87vh" }}>
            {_renderFilterToolbar()}

            {selectedListType !== null && selectedListType?.value === "map"
              ? _renderMapFunc()
              : _renderListFunc()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="dashboard"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9997,
        background: "rgba(255,255,255,0.4)",
      }}
    >
      {/* two TwoColumnLayout for dashboard */}
      <main className="main">{__renderMainSection()}</main>
    </div>
  );
};

export default CommunityAssets;
