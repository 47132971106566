import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDocRequest, resetFetchAllDoc } from "../../views/case-summary/store/fetch-all-document.slice";
import useLegacySupport from "../hooks/useLegacySupported";

const CustomCellDownloads = (props: any) => {

    const leagcy = useLegacySupport();
    const dispatch = useDispatch();
    const limit = 2;
    const isDelete = props.isDelete || false;

    const filesData: any = useSelector((state: any) => state.fetchAllDocData);

    const [isShow, setIsShow] = useState(props.showAll || false)
    const [data, setData] = useState(props.data)

    useEffect(() => {
        try {
            __fetchFileRecords();
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        if (filesData.isSuccess && filesData.data !== null) {

            const mapData = filesData.data.data.map((item: any) => {
                return {
                    ...item,
                    name: item.docName,
                    link: item.docFilePath
                }
            });

            setData(mapData);
            dispatch(resetFetchAllDoc());
        } else if (filesData.isError) {
            dispatch(resetFetchAllDoc());
        }
    }, [filesData]);


    const __fetchFileRecords: any = () => {
        if (props?.caseId !== undefined) {
            const obj: any = {
                token: leagcy.token,
                recordId: props.caseId,
                dspId: props.dspId
            };

            dispatch(fetchAllDocRequest(obj));
        }
    }

    const __onClickDelete = (index: any) => {
        const tempData = [...data];
        tempData[index].isDeleted = true;
        setData(tempData);

        props.onDelete(data[index]);
    }

    const ShowMoreButton = () => {
        if (data.length <= limit) return null;

        return <button onClick={() => setIsShow(!isShow)} className="btn btn-secondary text-left">{!isShow ? "+" : "-"}</button>
    }


    return (
        <div>{
            (data && data.length > 0) ?
                data.map((item: any, index: number) => {
                    if (index > limit - 1 && !isShow) return null;

                    return <div key={"file" + index} className="file-title d-flex justify-content-between">
                        <div>
                            <button
                                type={"button"}
                                onClick={() => props.onDownload(item.link)}
                                className="btn btn-link text-left">
                                {item.name.length > 20 ? item.name.slice(0, 20) + "... ." + item.name.slice(-3) : item.name}
                            </button>
                        </div>

                        {
                            isDelete && item.docId && <div><button
                                type="button"
                                disabled={item.isDeleted === true}
                                className={"btn btn-outline-danger btn-sm"}
                                onClick={() => __onClickDelete(index)}>Delete</button></div>
                        }

                    </div>
                }) : null
        }

            <ShowMoreButton />
        </div>
    )

}

export default CustomCellDownloads;