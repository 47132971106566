import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { fetchFeedbackCSADDRequest, fetchFeedbackCSEditRequest, fetchFeedbackCSRequest, resetFeedbackCSAdd, resetFeedbackCSEdit } from "../../views/case-summary/store/actions";
import { TextArea, TextField, DropDown, Button } from "../../components";
import { TOKEN, getData } from "../../utils/Storage";
import { closeIcon } from "../../assets/icons";

// Functional component for Add feedback
const FeedbackPopup = (props: any) => {
    // State for storing the "involve case" data
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const dspId = props.dspId;
    const caseSummaryType = props.caseSummaryType;
    const oneViewReference = props.oneViewReference;
    const feedbackId = props.feedbackId;


    const feedbackCsAdd: any = useSelector<any>((state) => state.feedbackCsAdd);
    const feedbackCsEdit: any = useSelector<any>((state) => state.feedbackCsEdit);

    const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
    // TODO: edit notes state
    // const [notes, setNotes] = useState(props.notes)

    const processData = () => {
        const formObj: any = {};
        props?.feedbackList?.gridColumns?.forEach((element: any) => {
            if (element.isEditable === true && element.isVisible === true) {
                let value = element.value || "";
                formObj[element.name] = value;
            }
        });
        return formObj
    }

    const [formData, setFormData] = useState<any>(processData);



    useEffect(() => {
        getData(TOKEN).then((res: any) => {
            let token;
            if (res?.provider === "msal") {
                token = { isLegacy: false, token: res.token }
            } else {
                token = { isLegacy: true, token: res.token };
            }
            setToken(token);
        })
    }, [])

    useEffect(() => {
        if (
            feedbackCsAdd.isSuccess &&
            feedbackCsAdd.data !== null
        ) {
            feedbackCsApiCall(token)
            props.onCancel()
            dispatch(resetFeedbackCSAdd());
        } else if (feedbackCsAdd.isError) {
            toast.error(
                feedbackCsAdd.data.error.data.title ||
                feedbackCsAdd.data.error.status
            );
            dispatch(resetFeedbackCSAdd());
        }


        if (
            feedbackCsEdit.isSuccess &&
            feedbackCsEdit.data !== null
        ) {
            feedbackCsApiCall(token)
            props.onCancel()
            dispatch(resetFeedbackCSEdit());
        } else if (feedbackCsEdit.isError) {
            toast.error(
                feedbackCsEdit.data.error.data.title ||
                feedbackCsEdit.data.error.status
            );
            dispatch(resetFeedbackCSEdit());
        }
    }, [feedbackCsEdit, feedbackCsAdd]);

    const AddfeedbackCsApiCall = () => {

        let body = {
            dspId: dspId,
            caseSummaryType: caseSummaryType,
            sectionId: formData.SectionName,
            oneViewReference: oneViewReference,
            notes: formData.Notes,
        };

        dispatch(fetchFeedbackCSADDRequest({ token: token, params: body }));
    }

    const EditfeedbackCsApiCall = () => {
        let body = {
            feedbackId: feedbackId,
            dspId: dspId,
            caseSummaryType: caseSummaryType,
            sectionId: formData.sectionId,
            oneViewReference: oneViewReference,
            notes: formData.notes,

        };

        dispatch(fetchFeedbackCSEditRequest({ token: token, params: body }));
    }


    const feedbackCsApiCall = (token: any) => {
        let body = {
            token: token,
            dspId: dspId,
            caseSummaryType: caseSummaryType,
            oneViewReference: oneViewReference
        };
        dispatch(fetchFeedbackCSRequest({ token: token, params: body }));
    }



    const renderHeader = () => {
        return (
            <div className="modal-header">
                <h5 className={"aside-top-bar " + (props.title !== '' ? "justify-content-sm-between" : "")} id="exampleModalLabel">
                    {props.title}
                </h5>
                <button
                    style={{ marginRight: 12, backgroundColor: '#5464B0', borderColor: '#5464B0', borderWidth: 1 }}
                    className="btn btn-transparent"
                    onClick={() => props.onCancel()
                    }
                >
                    <img src={closeIcon} alt="Close icon" />
                </button>
            </div>
        )

    }


    const handleChange: any = (e: any) => {
        const { name, value } = e;
        setFormData({ ...formData, [name]: value });
    };

    const handleDropdownChange: any = (e: any) => {
        const { name, value } = e;
        setFormData({ ...formData, [name]: value });
    };


    const parseDataForDropDown = (data: any) => {
        if (data === null || data === undefined) {
            return []
        }

        return data.map((option: any) => { return { "id": option.valueToStore, "title": option.textToDisplay, "value": option.valueToStore } })
    }


    const __renderLayout = useCallback((items: any, index: any) => {
        if (!items.isVisible || !items.isEditable) {
            return;
        }

        let columnsClassess = "col-12  pb-4";

        let itemsToRender = [];
        switch (items.dataType) {
            case "picklist":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <DropDown
                            label={items.displayName}
                            labelExternal={true}
                            key={"toolbar"}
                            name={props.name}
                            data={parseDataForDropDown(items.pickListValues)}
                            defaultSelected={formData[items.name]}
                            isMultiSelectPickList={false}

                            onChange={(e: any) => handleDropdownChange({ name: items.name, value: e[0].value })}
                        />
                    </div>
                );
                break;
            case "numeric":
            case "date":
            case "text":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <TextField
                            key={"text_field" + index}
                            label={items.displayName}
                            labelExternal={true}
                            name={items.name}
                            placeholder={items.placeholder}
                            type={items.dataType}
                            defaultSelected={formData[items.name]}
                            disabled={!items.isEditable}
                            value={formData[items.name]}
                            onChange={handleChange}
                        />
                    </div>
                );
                break;
            case "string":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <TextArea
                            key={"text_field" + index}
                            label={items.displayName}
                            labelExternal={true}
                            name={items.name}
                            placeholder={items.placeholder}
                            disabled={!items.isEditable}
                            defaultSelected={formData[items.name]}
                            onChange={handleChange}
                        />
                    </div>
                );
                break;
            default:
                return null;
        }

        return itemsToRender;
    }, [formData]);

    const RenderForm = () => {
        return (props.feedbackList.gridColumns?.map((items: any, index: any) => __renderLayout(items, index)));
    }

    const renderBody = () => {
        return (
            <div className="modal-body">
                <div className="content-container">
                    {RenderForm()}
                </div>

                {/* <div className="filter-fields px-3 pb-3">
                    <h3 className="title">{t('NOTES')}</h3>
                    <input 
                        aria-multiline={true} 
                        type="text"
                        name="notes"
                        id="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)} 
                    />
                </div> */}
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="modal-footer">
                <Button
                    key={""}
                    title={t('CANCEL')}
                    className="button cancel-button"
                    type="button"
                    onClick={() => props.onCancel()}

                />
                <Button
                    key={""}
                    title={props.isFrom == 'edit' ? feedbackCsEdit.isFetching : feedbackCsAdd.isFetching ? t('REQUEST') : t('SAVE')}
                    className={"button save-button width6" + (feedbackCsEdit.isFetching || feedbackCsAdd.isFetching ? "btn-disabled width6" : "")}
                    type="submit"
                    disabled={props.isFrom == 'edit' ? feedbackCsEdit.isFetching : feedbackCsAdd.isFetching}

                />
            </div>
        )
    }
    return (
        <div className="xan-modal feedback-popup">
            <div
                className="modal fade show"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {renderHeader()}
                        <form
                            className="information-governance-container"
                            onSubmit={(e) => {
                                e.preventDefault();

                                if (props.isFrom == 'edit') {
                                    EditfeedbackCsApiCall()
                                } else {
                                    AddfeedbackCsApiCall()
                                }
                            }}
                        >
                            {renderBody()}
                            {renderFooter()}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default FeedbackPopup;
