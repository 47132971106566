import { readFile } from "fs";
import { useState } from "react";
import { toast } from "react-toastify";

interface FileUploadProps {
  title: string;
  name: string;
  type: string;
  placeholder: string;
  onChange: any;
  data: any;
  fileTypes: any;
  isMultipleFileUpload: boolean;
  value?: string;
  // error: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const fileTypes = props.fileTypes.length > 0 ? props.fileTypes : [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "image/tiff",
    "message/rfc822",
    "application/vnd.ms-outlook",
    "*.msg"
  ];
 

  let data = props.data ? props.data : [];

  const [showAvailableFileTypes, setShowAvailableFileTypes] = useState(false);

  const onChange = (e: any) => {
    const file = e.target.files;
    try {
      if (!file) {
        toast.error("Please upload a file");
        return;
      }

      __processFiles(file);

    } catch (error) {
      toast.error("Error in selecting file");
    }

  }

  const __processFiles = async (resFiles: any) => {
    for (let fileIndex in resFiles) {
      if (resFiles.hasOwnProperty(fileIndex)) {
        const item = resFiles[fileIndex];

        // Check if file type matches any of the allowed types
        const isValidFile = fileTypes.some((type: string) => {
          // handle msg type file 
          if (type.startsWith('*.')) {
            const extension = type.substring(1);
            return item.name.toLowerCase().endsWith(extension.toLowerCase());
          }
          // Handle MIME type pattern
          return type === item.type;
        });

        if (isValidFile) {
          const tempFiles = [...data, {
            attachment: item, isUploading: false, isUploaded: false, isError: false, name: item.name, message: ""
          }];

          props.onChange(tempFiles)
        } else {
          toast.error("Please upload a valid file");
        }
      }
    }
  }

  const onClickRemoveFile = (file: any, index: number) => {
    const newFiles = data.filter((item: any, fileIndex: number) => fileIndex !== index);

    props.onChange(newFiles)
  }

  return (<div>
    <h6 className="involve-case-title d-flex justify-content-between">
      <span>{props.title}</span>

      <button type="button" className="btn btn-outline-secondery btn-sm ml-3" onClick={() => setShowAvailableFileTypes(!showAvailableFileTypes)}>
        <span className="badge badge-secondary">info</span>
      </button>
    </h6>
    {
      showAvailableFileTypes && <p className="text-muted"><small>Allowed files {fileTypes.join(", ")}</small></p>
    }

    <div className="form-group pb-3">
      <input type="file" name={props.name} multiple={props.isMultipleFileUpload || false} onChange={onChange} />
    </div>

    {data.length > 0 &&
      <div className="fileNames">
        {
          data.map((file: any, index: any) => {
            return (
              <div key={index} className="file d-flex justify-content-between">
                <div><p className={file.isUploaded ? "text-success" : file.isError ? "text-danger" : ""}>
                  <span>{file.name.length > 20 ? file.name.slice(0, 20) + "... ." + file.name.slice(-3) : file.name}
                    {file.isUploading && <span className="text-primary">Uploading...</span>}
                  </span>
                </p>
                  <p>{file.message !== "" ? file.message : ""}</p>
                </div>

                <div>
                  <p>
                    <button
                      type={"button"}
                      className="btn btn-outline-danger btn-sm"
                      disabled={file.isUploaded || file.isUploading}
                      onClick={() => onClickRemoveFile(file, index)}>Remove</button>
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    }
  </div>)
};

export default FileUpload;
