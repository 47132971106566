// Import the types to be used for dispatching actions
import * as type from "./types";

/**
 * Dispatches an action to fetch the Case Summary Configuration.
 *
 * @param {any} params - Parameters needed for fetching Case Summary Configuration.
 * @returns {object} - The action object.
 */
export const fetchCaseSummaryConfig = (params: any) => {
    return { type: type.CASE_SUMMARY_CONFIG_CM_REQUEST, payload: params }
}

/**
 * Resets the state of the Case Summary Configuration.
 *
 * @returns {object} - The action object.
 */
export const resetCaseSummaryConfig = () => {
    return { type: type.RESET_CASE_SUMMARY_CONFIG_CM, payload: null }
}


/**
 * Dispatches an action to fetch the Case Summary Configuration.
 *
 * @param {any} params - Parameters needed for fetching Case Summary Configuration.
 * @returns {object} - The action object.
 */
export const downloadAssets = (params: any) => {
    return { type: type.DOWNLOAD_ASSETS_REQUEST, payload: params }
}

/**
 * Resets the state of the Case Summary Configuration.
 *
 * @returns {object} - The action object.
 */
export const resetDownloadAssets = () => {
    return { type: type.RESET_DOWNLOAD_ASSETS, payload: null }
}

/**
 * Dispatches an action to fetch Overview Reference data.
 *
 * @param {any} params - Parameters needed for fetching Overview Reference.
 * @returns {object} - The action object.
 */
export const fetchOverviewreference = (params: any) => {
    return { type: type.FETCH_OVERVIEWREFERENCE_CM_REQUEST, payload: params }
}

/**
 * Resets the state of the Overview Reference.
 *
 * @returns {object} - The action object.
 */
export const resetFetchOverviewreference = () => {
    return { type: type.RESET_FETCH_OVERVIEWREFERENCE_CM, payload: null }
}


/**
 * Dispatches an action to fetch Overview Reference data.
 *
 * @param {any} params - Parameters needed for fetching Overview Reference.
 * @returns {object} - The action object.
 */
export const fetchOverviewreferenceAccess = (params: any) => {
    return { type: type.FETCH_OVERVIEWREFERENCE_ACCESS_CM_REQUEST, payload: params }
}

/**
 * Resets the state of the Overview Reference.
 *
 * @returns {object} - The action object.
 */
export const resetFetchOverviewreferenceAccess = () => {
    return { type: type.RESET_FETCH_OVERVIEWREFERENCE_ACCESS_CM, payload: null }
}

/**
 * Dispatches an action to fetch Case Summary Data.
 *
 * @param {any} params - Parameters needed for fetching Case Summary Data.
 * @returns {object} - The action object.
 */
export const fetchCaseSummaryData = (params: any) => {
    return { type: type.CASE_SUMMARY_DATA_CM_REQUEST, payload: params }
}

/**
 * Resets the state of the Case Summary Data.
 *
 * @returns {object} - The action object.
 */
export const resetCaseSummaryData = () => {
    return { type: type.RESET_CASE_SUMMARY_DATA_CM, payload: null }
}

/**
 * Dispatches an action to update Case Data.
 *
 * @param {any} params - Parameters needed for updating Case Data.
 * @returns {object} - The action object.
 */
export const updateData = (params: any) => {
    return { type: type.CASE_DATA_CM_UPDATE, payload: params }
}

/**
 * Dispatches an action to fetch Information Governance Data.
 *
 * @param {any} params - Parameters needed for fetching Information Governance Data.
 * @returns {object} - The action object.
 */
export const fetchInformationGovernanceData = (params: any) => {
    return { type: type.INFORMATION_GOVERNANCE_DATA_CM_REQUEST, payload: params }
}

/**
 * Resets the state of Information Governance Data.
 *
 * @returns {object} - The action object.
 */
export const resetInformationGovernanceData = () => {
    return { type: type.RESET_INFORMATION_GOVERNANCE_DATA_CM, payload: null }
}

/**
 * Dispatches an action to fetch IGAPI Data.
 *
 * @param {any} params - Parameters needed for fetching IGAPI Data.
 * @returns {object} - The action object.
 */
export const fetchIGData = (params: any) => {
    return { type: type.IGAPI_DATA_REQUEST, payload: params }
}

/**
 * Resets the state of the IGAPI Data.
 *
 * @returns {object} - The action object.
 */
export const resetIGData = () => {
    return { type: type.RESET_IGAPI_DATA, payload: null }
}

/**
 * Resets the state of the Feedback Data start.
 *
 * @returns {object} - The action object.
 */
export const resetFeedbackCS = () => {
    return { type: type.RESET_FEEDBACK_CM, payload: null }
}
export const fetchFeedbackCSRequest = (params: any) => {
    return { type: type.FEEDBACK_CM_REQUEST, payload: params }
}

export const resetFeedbackCSColumn = () => {
    return { type: type.RESET_FEEDBACK_CM_COLUMNS, payload: null }
}

export const fetchFeedbackCSColumnRequest = (params: any) => {
    return { type: type.FEEDBACK_CM_COLUMNS_REQUEST, payload: params }
}






/**
 * Resets the state of the Feedback Data end.
 *
 * @returns {object} - The action object.
 */


export const fetchFeedbackCSADDRequest = (params: any) => {
    return { type: type.FEEDBACK_CM_ADD_REQUEST, payload: params }
}

export const resetFeedbackCSAdd = () => {
    return { type: type.RESET_FEEDBACK_CM_ADD, payload: null }
}
export const resetFeedbackCSDelete = () => {
    return { type: type.RESET_FEEDBACK_CM_DELETE, payload: null }
}
export const fetchFeedbackCSDeleteRequest = (params: any) => {
    return { type: type.FEEDBACK_CM_DELETE_REQUEST, payload: params }
}
export const resetFeedbackCSEdit = () => {
    return { type: type.RESET_FEEDBACK_CM_EDIT, payload: null }
}
export const fetchFeedbackCSEditRequest = (params: any) => {
    return { type: type.FEEDBACK_CM_EDIT_REQUEST, payload: params }
}

/**
 * Dispatches an action to outcome.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const addCaseDetailAll = (params: any) => {
    return { type: type.CASE_DETAIL_ALL_REQUEST, payload: params }
}


/**
 * Resets the state of outcome Data.
 *
 * @returns {object} - The action object.
 */
export const resetCaseDetailAll = () => {
    return { type: type.RESET_CASE_DETAIL_ALL, payload: null }
}



/**
 * Dispatches an action to Add edit popup .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const addEditPopup = (params: any) => {
    return { type: type.ADD_EDIT_POPUP_REQUEST, payload: params }
}


/**
 * Resets the state of  Add edit popup.
 *
 * @returns {object} - The action object.
 */
export const resetAddEditPopup = () => {
    return { type: type.RESET_ADD_EDIT_POPUP, payload: null }
}


/**
 * Dispatches an action to pickListData .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const pickListDataPopup = (params: any) => {
    return { type: type.PICK_LIST_DATA_REQUEST, payload: params }
}


/**
 * Resets the state of  pickListData.
 *
 * @returns {object} - The action object.
 */
export const resetPickListData = () => {
    return { type: type.RESET_PICK_LIST_DATA, payload: null }
}




/**
 * Dispatches an action to fetch all engagment tracker data .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchAllEngagmentTracker = (params: any) => {
    return { type: type.FETCH_ALL_ENGAGMENT_TRACKER_REQUEST, payload: params }
}

/**
 * Resets the state of  fetch all engagment tracker data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchAllEngagmentTracker = () => {
    return { type: type.RESET_FETCH_ALL_ENGAGMENT_TRACKER, payload: null }
}

/**
 * Dispatches an action to Add edit Engagment popup .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
// export const addEditEngagmentPopup = (params: any) => {
//     return { type: type.ADD_EDIT_ENGAGMENT_POPUP_REQUEST, payload: params }
// }


/**
 * Resets the state of  Add edit popup.
 *
 * @returns {object} - The action object.
 */
// export const resetAddEditEngagmentPopup = () => {
//     return { type: type.RESET_ADD_EDIT_ENGAGMENT_POPUP, payload: null }
// }


/**
 * Dispatches an action to Add outcome .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const addEngagmentData = (params: any) => {
    return { type: type.ADD_ENGAGMENT_REQUEST, payload: params }
}


/**
 * Resets the state of  Add outcome.
 *
 * @returns {object} - The action object.
 */
export const resetAddEngagmentData = () => {
    return { type: type.RESET_ADD_ENGAGMENT, payload: null }
}


/**
 * Dispatches an action to getEditOutcomeData .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const getEditEngagmentData = (params: any) => {
    return { type: type.GET_EDIT_ENGAGMENT_DATA_REQUEST, payload: params }
}

/**
 * Resets the state of  getEditOutcomeData.
 *
 * @returns {object} - The action object.
 */
export const resetGetEditEngagmentData = () => {
    return { type: type.RESET_GET_EDIT_ENGAGMENT_DATA, payload: null }
}

/**
 * Dispatches an action to editOutcomeData .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const editEngagmentData = (params: any) => {
    return { type: type.EDIT_ENGAGMENT_DATA_REQUEST, payload: params }
}

/**
 * Resets the state of  editOutcomeData.
 *
 * @returns {object} - The action object.
 */
export const resetEditEngagmentData = () => {
    return { type: type.RESET_EDIT_ENGAGMENT_DATA, payload: null }
}


/**
 * Dispatches an action to deleteOutcomeData .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const deleteEngagmentData = (params: any) => {
    return { type: type.DELETE_ENGAGMENT_DATA_REQUEST, payload: params }
}


/**
 * Resets the state of  deleteOutcomeData.
 *
 * @returns {object} - The action object.
 */
export const resetDeleteEngagmentData = () => {
    return { type: type.RESET_DELETE_ENGAGMENT_DATA, payload: null }
}

/**
 * Dispatches an action to pickListData .
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const pickListEngagmentDataPopup = (params: any) => {
    return { type: type.PICK_LIST_ENGAGMENT_DATA_REQUEST, payload: params }
}


/**
 * Resets the state of  pickListData.
 *
 * @returns {object} - The action object.
 */
export const resetPickEngagmentListData = () => {
    return { type: type.RESET_PICK_LIST_ENGAGMENT_DATA, payload: null }
}


/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchHHC = (params: any) => {
    return { type: type.HHC_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchHHC = () => {
    return { type: type.RESET_HHC, payload: null }
}



/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchIframeData = (params: any) => {
    return { type: type.IFRAME_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetfetchIframeData = () => {
    return { type: type.RESET_IFRAME, payload: null }
}




/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const emmitIframeEvents = (params: any) => {
    return { type: type.EMMIT_IFRAME_EVENTS_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetEmmitIframeEvents = () => {
    return { type: type.RESET_EMMIT_IFRAME_EVENTS, payload: null }
}

