import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { addEditPopup, deleteEngagmentData, downloadAssets, editEngagmentData, emmitIframeEvents, fetchAllEngagmentTracker, getEditEngagmentData, resetAddEditPopup, resetAddEngagmentData, resetDeleteEngagmentData, resetDownloadAssets, resetEmmitIframeEvents, resetFetchAllEngagmentTracker, resetGetEditEngagmentData } from "../store/actions";
import { Button, CustomCellDownloads,  DropDownMenu } from "../../../components";
import AddEngagmentPopup from "./addEngagmentPopup";
import IFrameWrapper from "./iFrameWrapper";
import KendoGrid from "../../../components/containers/kendo-grid"
import { GridLoader } from "../../dashboard/component";
import caseSummaryService from "../services/case-summary-service";
import CustomAction from "../../dashboard/component/customAction";
import { communityAssetsEvent } from "../../../utils/events";
import { fetchChildGridDataById } from "../../dashboard/store/actions";
import SCVPopup from "./SelectSCVIdPopup";
import { printRequest } from "../store/print-document.slice";

const EngagmentTrackerGrid: React.FC<any> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();


    const childGridData = useSelector((state: any) => state.childGridData);
    const fetchAllEngagmentTrackerData = useSelector((state: any) => state.fetchAllEngagmentTrackerCM);
    const addEditEngagmentData: any = useSelector<any>((state) => state.addEditCM);
    const getEditEngagmentDetailData = useSelector((state: any) => state.getEditEngagmentCM);
    const deleteEngagmentDetailData = useSelector((state: any) => state.deleteEngagmentDetailDataCM);
    const emmitIframeEventsData = useSelector((state: any) => state.emmitIframeEvents);
    const downloadAssetsData = useSelector((state: any) => state.downloadAssets);
    // const printDocData = useSelector((state: any) => state.printDocData);

    const [engagmentTrackerListData, setEngagmentTrackerListData] = useState(null);
    const [addEngagmentModel, setAddEngagmentModel] = useState<Boolean>(false);
    const [addEngagmentIframeModel, setAddEngagmentIframeModel] = useState<Boolean>(false);
    const [iframeConfigData, setIframeConfigData] = useState<any>(null);
    const [addEngagmentModelDataState, setAddEngagmentModelDataState] = useState<any>(null);
    const [selectedActionPicklistVal, setSelectedActionPicklistVal] = useState<any>("");
    const [selectedActionPicklistTitle, setSelectedActionPicklistTitle] = useState<any>("");
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [selectedSCVId, setSelectedSCVId] = useState<string>('');
    const [SVCData, setSVCData] = useState<any>(null);
    const [showSVCPopup, setShowSVCPopup] = useState(false);
    const [isReferral, setIsReferral] = useState(false);
    const [formData, setFormData] = useState<any>([]);
    const [tabs, setTabs] = useState(props.tabs);

    useEffect(() => {
        // Fetch Information addCaseDetailAll
        fetchEngagmentTrackerData();
    }, [])

    useEffect(() => {
        if (props.tabs.tabId !== tabs.tabId) {
            setTabs(props.tabs);
            setEngagmentTrackerListData(null);
            // Fetch Information addCaseDetailAll
            fetchEngagmentTrackerData();
        }
    }, [props.tabs])

    useEffect(() => {
        try {
            if (engagmentTrackerListData !== fetchAllEngagmentTrackerData.data) {
                if (fetchAllEngagmentTrackerData.isSuccess && fetchAllEngagmentTrackerData.data !== null) {
                    // setEngagmentTrackerListData(fetchAllEngagmentTrackerData.data)
                    const masterData = fetchAllEngagmentTrackerData.data;

                    let data: any = caseSummaryService.processDataForKendoGrid(masterData);

                    setEngagmentTrackerListData(data);
                    dispatch(resetFetchAllEngagmentTracker())
                } else if (fetchAllEngagmentTrackerData.isError) {
                    console.error(fetchAllEngagmentTrackerData)
                    toast.error(fetchAllEngagmentTrackerData.data.error.message)
                    dispatch(resetFetchAllEngagmentTracker())
                    setAddEngagmentModel(false);
                }
            }

        } catch (e: any) {
            console.error(e.message)
        }

    }, [fetchAllEngagmentTrackerData]);

    useEffect(() => {
        if (emmitIframeEventsData.isSuccess && emmitIframeEventsData.data !== null) {
            fetchEngagmentTrackerData();
            dispatch(resetEmmitIframeEvents());
        } else if (emmitIframeEventsData.isError) {
            toast.error(emmitIframeEventsData?.data?.error);
            dispatch(resetEmmitIframeEvents());
        }
    }, [emmitIframeEventsData]);

    useEffect(() => {
        try {

            if (deleteEngagmentDetailData.isSuccess && deleteEngagmentDetailData.data == null) {
                // toast.success("Case Details data delete successful");
                dispatch(resetDeleteEngagmentData());
                fetchEngagmentTrackerData();
            } else if (deleteEngagmentDetailData.isError) {
                toast.error(deleteEngagmentDetailData?.data?.error);
                dispatch(resetDeleteEngagmentData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [deleteEngagmentDetailData])

    useEffect(() => {
        // Process received getEditEngagmentDetailData data
        try {
            if (getEditEngagmentDetailData.isSuccess && getEditEngagmentDetailData.data !== null) {
                const data = getEditEngagmentDetailData?.data;
                if (data[0].type === "iframe") {
                    const controls = data[0];
                    setIframeConfigData(controls);
                    setAddEngagmentIframeModel(true);
                } else {
                    setAddEngagmentModelDataState({
                        popupForm: {
                            controls: getEditEngagmentDetailData.data
                        }
                    });

                    setAddEngagmentModel(true);
                }

                dispatch(resetGetEditEngagmentData());
            } else if (getEditEngagmentDetailData?.isError) {
                // toast.error("Unable to fetch record details."); 
                // TODO: to be worked on this entire logic again 
                dispatch(resetGetEditEngagmentData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [getEditEngagmentDetailData]);

    useEffect(() => {
        // Process received addOutcomeModel data
        if (addEditEngagmentData.isSuccess && addEditEngagmentData.data !== null) {
            const data = addEditEngagmentData.data.popupForm;
            setIsReferral(false);
            const controls = data.controls[0];

            if (data.controls[0].type === "iframe") {
                if (controls.isScvIdRequired) {    
                    dispatch(fetchChildGridDataById({
                        token: props.token,
                        param: {
                            gridId: props.gridId,
                            oneViewReference: props.selectedCaseReference,
                            dspId: props.dsp,
                        }
                    }));
                } else {
                    setAddEngagmentIframeModel(true);
                }

                fetchEngagmentTrackerData();
                setIframeConfigData(controls);

            } else if (data.controls[0].type === "communityassets") {
                
                if (controls.isScvIdRequired) {
                    setIsReferral(true);
                    dispatch(fetchChildGridDataById({
                        token: props.token,
                        param: {
                            gridId: props.gridId, 
                            oneViewReference: props.selectedCaseReference,
                            dspId: props.dsp,
                        }
                    }));
                } else {
                    window.dispatchEvent(communityAssetsEvent)
                }

            }
            else {
                setAddEngagmentModelDataState(addEditEngagmentData.data);
                setAddEngagmentModel(true);
                setIsReferral(false);
            }

            dispatch(resetAddEditPopup());
        } else if (addEditEngagmentData.isError) {
            toast.error(addEditEngagmentData?.data?.error?.data);
            dispatch(resetAddEditPopup());
            setAddEngagmentIframeModel(false);
            setAddEngagmentModel(false);
            setShowSVCPopup(false);
        }
    }, [addEditEngagmentData]);


    useEffect(() => {
        if (childGridData.isSuccess && childGridData.data !== null) {
            if (childGridData.data.rowCount > 0) {
                if (isReferral) {
                    if (childGridData.data.rowCount > 1) {
                        setSVCData(childGridData.data);
                        setShowSVCPopup(true);
                    } else if (childGridData.data.rowCount === 1) {
                        communityAssetsEvent.detail.selectedSCVId = childGridData.data.gridRows[0].values[1];
                        window.dispatchEvent(communityAssetsEvent)
                    }
                } else {
                    if (childGridData.data.rowCount > 1) {
                        setSVCData(childGridData.data);
                        setShowSVCPopup(true);
                    } else if (childGridData.data.rowCount === 1) {
                        setSelectedSCVId(childGridData.data.gridRows[0].values[1]);
                        setAddEngagmentIframeModel(true);
                    }
                }

            }
            else {
                toast.error("No SCV Id found");
            }
        } else if (childGridData.isError) {
            toast.error(childGridData?.data?.error);
        }
    }, [childGridData]);





    useEffect(() => {
        try {
            if (downloadAssetsData.isSuccess && downloadAssetsData.data !== null) {
                window.open(downloadAssetsData.data, "_blank");
                toast.success("Download successful");
                dispatch(resetDownloadAssets());
            } else if (downloadAssetsData.isError) {
                toast.success("Failed to download resource");
                toast.error(downloadAssetsData?.data?.error);
                dispatch(resetDownloadAssets());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [downloadAssetsData])


    const __onCustomAction = (res: any, controlId: string) => {
        try {
            switch (res.action) {
                case "delete":
                    dispatch(deleteEngagmentData({
                        token: props.token,
                        param: {
                            controlId: res.data.linkFormControlId || controlId,
                            dspId: props.dsp,
                            id: res.data.linkDataId || res.data.Id,
                        }
                    }))
                    break;
                case "edit":
                    setSelectedRecord(res.data);
                    setSelectedSCVId(res.data.scvid);
                    setSelectedActionPicklistTitle(props.tabs.title)
                    dispatch(
                        getEditEngagmentData({
                            token: props.token,
                            param: {
                                // controlId: res.dataItem.Activitytype,
                                controlId: res.data.linkFormControlId || controlId,
                                dspId: props.dsp,
                                id: res.data.linkDataId || res.data.Id,
                            },
                        }));
                    break;
                case "upload":
                    setSelectedRecord(res.data);

                    break;
                default:
                    break;

            }
            setAddEngagmentModel(false);
            setAddEngagmentModelDataState(null);
        } catch (res) {
            console.log(res);
        }
    }

    const handleChange = (e: any, controlId: string) => {
        let value = e.value;
        const tempForm = [...formData];

        const index = tempForm.findIndex((item: any) => item.controlId === controlId);

        if (index > -1) {
            if (value === tempForm[index].values) {
                tempForm.splice(index, 1);
            } else {
                tempForm[index].values = e.value;
            }
        } else {
            tempForm.push({
                controlId: controlId,
                values: e.value,
            });
        }

        setFormData(tempForm);
    };


    const fetchEngagmentTrackerData = () => {
        try {
            dispatch(
                fetchAllEngagmentTracker({
                    token: props.token,
                    param: {
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        controlId: props.controlId
                    },
                    body: formData,
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }
    }


    const renderEngagmentTrackerList = () => {
        return (<KendoGrid
                    gridData={engagmentTrackerListData}
                    isSelectable={false}
                    isCustomAction={__IsCustomAction()}
                    customeActionLayout={__renderCustomActionLayout}
                    customCell={(res: any) => __customCell(res)}
                    isPaginated={true}
                />
        )
    }


    const __IsCustomAction = () => {
        return props.tabs.datagrid.allowEdit || props.tabs.datagrid.allowDelete
    }

    const __renderCustomActionLayout = (res: any) => {
        return <CustomAction
            data={res}
            allowDetails={false}
            allowEdit={res.actionEdit === "1" || props.tabs.datagrid.allowEdit}
            allowDelete={res.actionDelete === "1" || props.tabs.datagrid.allowDelete}
            allowUpload={false}
            onClick={(e: any) => __onCustomAction(e, props.controlId)}
        />
    }

    const __downloadAsset = (url: string) => {
        try {
            dispatch(downloadAssets({
                token: props.token,
                url: url
            }))
        } catch (e: any) {
            toast.error(e.message);
        }
    }


    const __customCell = (res: any) => {
        return {
            "DocFilePath": (data: any) => {
                return <td><CustomCellDownloads
                    data={data.dataItem.DocFilePath}
                    onDownload={(url: string) => __downloadAsset(url)}
                /></td>
            }
        }
    }


    const __fetchPopupConfig = (controlId: any) => {
        try {
            // Fetch Information add_edit_cofig
            setSelectedActionPicklistTitle(props.tabs.title);

            dispatch(
                addEditPopup({
                    token: props.token,
                    param: {
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        controlId: controlId,
                    },
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }

    }


    const __fetchPrintDoucments = (res: any) => {
        try {
            // "On selection the following call should be made api/pdf?oneviewReference={nnnnn}&printDocType={selectedId}&dspId={nnnn}"
            // Fetch Information add_edit_cofig
            const obj: any = {
                token: props.token,
                param: {
                    dspId: props.dsp,
                    oneViewReference: props.selectedCaseReference,
                    printDocType: res.value,
                    printDocName: res.title
                }
            }
            dispatch(
                printRequest(obj)
            );
        } catch (e: any) {
            toast.error(e.message);
        }

    }
    const __selectActionPicklist = (e: any, type: string) => {
        type = type.toLowerCase()

        if (type === "engagementtrackerprintactionbutton") {
            __fetchPrintDoucments(e)

        } else if (type === "engagementactionlist") {

            setSelectedActionPicklistVal(e.value);
            setSelectedActionPicklistTitle(e.title);

            setAddEngagmentIframeModel(false);
            setAddEngagmentModel(false);
            setShowSVCPopup(false);

            setSVCData(null);
            setSelectedSCVId('');

            __fetchPopupConfig(e.value);
        }


    }

    const parseDataForDropDown = (items:any) => {
        return items.map((obj: any) => {
            return {
                id: obj.valueToStore,
                title: obj.textToDisplay,
                value: obj.valueToStore
            }
        });

    }

    // Function to render layout based on item type
    const __renderToolbarLayout = () => {
        let itemsToRender: any = [];
        let actionPicklist: any = [];

        if (props?.tabs?.datagrid?.allowAdd === true && props?.tabs?.datagrid?.toolbar === undefined) {
            return <div className="filter-content pos-relative">
                <div className="filter-header">
                    <h3></h3>
                    <div style={{ justifyContent: "center", alignContent: "end" }}>
                        <Button
                            style={{ backgroundColor: "#ffffff", border: "none", color: "#5464B0", padding: "0.625rem 1.188rem", fontWeight: "600", borderRadius: "0.625rem" }}
                            key={""}
                            title={`${t("ADDKEYWORD")} ${props?.tabs?.title}`}
                            type={"button"}
                            onClick={() => __fetchPopupConfig(props.tabs.datagrid.dataGridId)}
                        />
                    </div>
                </div>
            </div>
        }

        props?.tabs?.datagrid?.toolbar?.controls.map((items: any, index: any) => {
            if (!items.visible) {
                return
            }

            switch (items.type) {
                case "selectpicker":
                    let selectedValue = '';
                    let data = formData.find((item: any) => {
                        return item.controlId === items.controlId 
                    });



                    if (data !== undefined) {
                        selectedValue = data.values;
                    } else {
                        selectedValue = items.defaultSelectedValue !== undefined ? items.defaultSelectedValue : undefined;
                    }


                    itemsToRender.push(
                        <div className="button-wrapper mx-1" style={{ width: "100%" }}>
                            <DropDownMenu
                                label={items.label}
                                labelExternal={false}
                                key={"filters"}
                                name={"name"}
                                data={parseDataForDropDown(items.picklistValues)}
                                defaultSelected={selectedValue}
                                isMultiSelectPickList={false}
                                onChange={(e) => handleChange(e, items.controlId)}
                            /></div>

                    );       
                    break;
                case "button":
                    itemsToRender.push(
                        <div
                            className=""
                            key={items.controlId}>
                            <button
                                className={items.name !== "clearButton" ? "btn btn-secondary btn-info-filter min-width6 close" : "btn btn-secondary btn-info-close min-width6 close"}

                                type={items.name === "clearButton" ? "reset" : "submit"}
                                key={items.controlId}
                                onClick={() => {
                                    if (items.name == "clearButton") {
                                        setFormData([]);

                                        fetchEngagmentTrackerData();
                                    }
                                }}
                            >{items.label}</button>
                        </div>
                    );
                    break;
                case "clear":
                    itemsToRender.push(
                        <div
                            className=""
                            key={items.controlId}>
                            <button
                                className={"btn btn-secondary btn-info-close min-width6 close"}
                                key={items.controlId}
                                type="reset"
                            >
                                {items.label}
                            </button>
                        </div>
                    );
                    break;
                case "actionPicklist":
                    
                    actionPicklist.push(
                        <div className="button-wrapper mx-1" style={{ width: "100%" }}>
                            <DropDownMenu
                                label={items.label}
                                labelExternal={false}
                                key={"filters"}
                                name={"name"}
                                data={parseDataForDropDown(items.picklistValues)}
                                defaultSelected={selectedActionPicklistVal}
                                isMultiSelectPickList={false}
                                onChange={(e) => __selectActionPicklist(e, items.name)}
                            /></div>
                    );
                    break;
                default:
                    return null;
            }
        })

        return <div className="filter-content-ET pos-relative">
            <div className="engagement-header"><div className="row">
                <div className="col-md-9 col-lg-9" key={"engagement-filters"}>
                    <form className="d-flex" onSubmit={(e) => {
                        e.preventDefault();

                        fetchEngagmentTrackerData();
                    }}>
                        {itemsToRender}
                    </form>
                </div>
                <div className="col-md-3 col-lg-3 d-flex">
                    {actionPicklist}
                </div>
            </div>
            </div>
        </div>;
    };

    const __submitSCVId = (type: string, data: string | null) => {
        try {
            if (data === null || type === "closeForm") {
                setShowSVCPopup(false);
            } else {
                if (isReferral) {
                    setSelectedSCVId(data);

                    communityAssetsEvent.detail.selectedSCVId = data;
                    window.dispatchEvent(communityAssetsEvent);
                    setShowSVCPopup(false);
                } else {
                    setSelectedSCVId(data);
                    setShowSVCPopup(false);
                    setAddEngagmentIframeModel(true);
                }

            }
        } catch (e) {
            toast.error("Error in fetching SCV Id");
        }
    }

    const __submitIframeModel = (type: string, data: any) => {
        setAddEngagmentIframeModel(false);
    }

    const __submitIgPluginAuth = (type: string, data: any) => {
        setAddEngagmentModel(false);
        fetchEngagmentTrackerData();
        setSelectedRecord(null);
    };

    const emmitEvents = (type: string, res: any) => {
        let params: any = {
            dspId: props.dsp,
            oneViewReference: props.selectedCaseReference,
            iFrameUrl: res.url,
            controlId: iframeConfigData.controlId,
            surveyJsFormId: iframeConfigData.surveyJsFormId,
        }

        if (iframeConfigData.addRecordApiEndPoint.parameters.includes("scvId")) {
            params["scvId"] = selectedSCVId.toString();
        }

        dispatch(emmitIframeEvents({
            token: props.token,
            url: iframeConfigData.addRecordApiEndPoint.endpointUrl.slice(4),
            param: params
        }));

        if (type === "complete") {
            setAddEngagmentIframeModel(false);
        }
    }

    return (
        <div className="engagement-grid">
            {__renderToolbarLayout()}

            {/* <div className="d-flex">
            <FormWrapper
                data={props?.tabs?.datagrid?.toolbar?.controls}
                type="horizontal"
                onChange={handleChange}
            />
            </div> */}
            {fetchAllEngagmentTrackerData.isFetching && engagmentTrackerListData !== null ? <GridLoader /> : renderEngagmentTrackerList()}
            
            {(addEngagmentModel) &&
                <AddEngagmentPopup
                    token={props.token}
                    dspId={props.dsp}
                    title={selectedActionPicklistTitle}
                    data={{ ...addEngagmentModelDataState, selectedRecord }}
                    isFetching={false}
                    oneViewReference={props.selectedCaseReference}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitIgPluginAuth("closeForm", res)
                        }
                        else {
                            __submitIgPluginAuth(type, res)
                        }
                    }}
                />}

            {(addEngagmentIframeModel) &&
                <IFrameWrapper
                    token={props.token}
                    title={iframeConfigData.label}
                    data={iframeConfigData}
                    param={{
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        scvid: selectedSCVId,
                        surveyJsFormId: iframeConfigData.surveyJsFormId,
                        controlId: iframeConfigData.controlId
                    }}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitIframeModel("closeForm", res)
                        }
                    }}

                    emmitEvents={emmitEvents}
                />}

            {(showSVCPopup) &&
                <SCVPopup
                    title={"Select SCV Id"}
                    data={SVCData}
                    onSubmit={__submitSCVId}
                />}

        </div >
    );
};

export default EngagmentTrackerGrid;
