import React from "react";

interface SoftDeletePopupProps {
  onClose: () => void;
  onConfirm: (e:any) => void;
}

const SoftDeletePopup: React.FC<SoftDeletePopupProps> = ({ onClose, onConfirm }) => {
  // TODO: Implement stylesheet in the scss
  return (
    <>
      <style>
        {`
          .soft-delete-popup {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
          }

          .popup-content {
            margin: 0 auto;
            background: white;
            border-radius: 8px;
            text-align: center;
            max-width: 500px;
            width: 90%;
          }

          .popup-header {
            padding: 10px;
            background-color: #3F4C89;
            color: white;
            border-radius: 8px 8px 0 0;
            margin-bottom: 20px;
          }

          .button-group {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
          }

          .btn {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }

          .btn-primary {
            background-color: #3F4C89;
            color: white;
          }

          .popup-message {
            font-size: 13px;
            margin-bottom: 20px;
            padding: 0 20px;
          }

          .popup-title {
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .popup-actions {
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
            gap: 10px;
            padding: 0 20px 10px;
          }
        `}
      </style>
         <div className="soft-delete-popup">
			  <div className="popup-content">
				  <div className="popup-header">
					  <span className="popup-title">Confirm Soft Delete</span>
		</div>
        
        <span className="popup-message">Are you sure you want to delete this record?</span>
        <div className="popup-actions">
          <button onClick={onClose} className="btn btn-secondary">Cancel</button>
          <button onClick={() => onConfirm({ action: "delete" })} className="btn btn-primary">Confirm</button>
        </div>
      </div>
		  </div>
		  

		  
    </>
  );
};

export default SoftDeletePopup;