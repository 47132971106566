import { createSelector, createSlice } from '@reduxjs/toolkit';
import { setInitialState } from "../../../store/InitialState";

const INITIAL_STATE = setInitialState({
	data: null,
	isFetching: false,
	isSuccess: false,
	isError: false,
	error: null,
});

const deleteDocSlice = createSlice({
  name: 'deleteDocSlice',
  initialState: INITIAL_STATE,
  reducers: {
    deleteRequest: (state) => { state.isFetching = true; },
    deleteSuccess: (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = action.payload; 
    },
    deleteFailuer: (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError= true;
        state.error = action.payload;
        },
    resetDelete: (state) => { 
        state.data = null; 
        state.error = null;
        state.isError = false; 
        state.isFetching = false;
        state.isSuccess = false;
    }
  }
});

// Exporting actions for saga and views 
export const { deleteRequest, deleteSuccess, deleteFailuer, resetDelete } = deleteDocSlice.actions;

// const selectLogin = (state: any) => state.deleteDocSlice;

// export const selectLoginData = createSelector(selectLogin, (login) => {
//     console.log('login', login);
//     return login.data;
// });

// Exporting deleteDocSlice reducer for combine reducer 
export default deleteDocSlice.reducer;