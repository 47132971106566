const TextField = (props: any) => {
  return (
    <div className={'filter-fields ' + props.additionalClass} key={props.name}>
      {props.labelExternal ? <h3 className="title">{props.label}</h3> : null }
      <input
        // {...props}
        type={props.type}
        name={props.name}
        value={props?.defaultSelected}
        className="form-control"
        disabled={props.disabled ? props.disabled : false}
        placeholder={props.placeholder !== "" ? props.placeholder : null}
        onChange={(e) => props.onChange({ name: props.name, value: e.target.value })}
      />
    </div>
  );
};

export default TextField;