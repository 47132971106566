const TextArea = (props: any) => {
  return (
    <div className={'filter-fields ' + props.additionalClass} key={props.name}>
      {props.labelExternal ? <h3 className="title">{props.label}</h3> : null}
      <textarea
        // {...props}
        name={props.name}
        className="form-control"
        disabled={props.disabled}
        placeholder={props.placeholder !== "" ? props.placeholder : null}
        onChange={(e) => props.onChange({ name: props.name, value: e.target.value })}>
          {props.defaultSelected}
          </textarea>
    </div>
  );
};

export default TextArea;
