import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { resetPassword, resetResetPassword } from "../store/actions";
import { Popup } from "../../dashboard/component";
import { closeIcon } from "../../../assets/icons";
import { resetEditCaseRecordById } from "../store/actions";
import { addEditPopup, resetAddEditPopup } from "../../case-summary/store/actions";
import EngagmentSelectPicker from "../../case-summary/components/EngagmentSelectPicker";
import TextField from "../../../components/atoms/textArea";

const EditCasePopup = (props: any) => {
    const dispatch = useDispatch();
    const editCaseData = useSelector((state: any) => state.editCaseDetails);
    const addEditOutcomeData: any = useSelector<any>((state) => state.addEditCM);

    const [formControls, setFormControls] = useState<any>(null);
    const [formData, setFormData] = useState<any>([]);

    useEffect(() => {
        dispatch(
            addEditPopup({
                token: props.token,
                param: {
                    dspId: props.dspId,
                    oneViewReference: props.data.Group_Ref,
                    controlId: props.controlId,
                },
            })
        );
    }, [])


    useEffect(() => {
        try {
            // Process received addOutcomeModel data
            if (addEditOutcomeData.isSuccess && addEditOutcomeData.data !== null) {
                setFormControls(addEditOutcomeData.data.popupForm);

                const tempFormData: any = [];
                addEditOutcomeData.data.popupForm.controls.forEach((element: any) => {
                    if (element.type !== "button") {
                        tempFormData.push(
                            {
                                "controlName": element.name,
                                "values": props.data[element.name]
                            })
                    }
                });

                setFormData(tempFormData);
                dispatch(resetAddEditPopup());
            } else if (addEditOutcomeData.isError) {
                toast.error(addEditOutcomeData?.data?.error?.data);

            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [addEditOutcomeData]);

    useEffect(() => {
        if (editCaseData.isSuccess && editCaseData.data !== null) {
            props.closeModal();
            dispatch(resetEditCaseRecordById())
        } else if (editCaseData.isError) {
            props.closeModal();
            toast.error(editCaseData.data.error.message)
            dispatch(resetEditCaseRecordById())
        }

    }, [editCaseData])

    const __submitCaseDetails = (e: any) => {
        e.preventDefault();

        try {
            if (formData.length === 0) {
                toast.error("All fields are required")
                return
            } else {
                props.onSubmit(formData)
            }
        } catch (e) {
            toast.error("Something went wrong")
        }

    }

    const __renderCaseEditDetailsTitle = () => {
        return (<div className="popup-title">
            <h3 className="title">Edit Case: {props.data.Group_Ref}</h3>
            <button
                className="close"
                onClick={() => props.closeModal()}
            >
                <img src={closeIcon} alt="Close icon" />
            </button>
        </div>)
    }

    

    const handleDropdownChange = (e: any) => {
        const { name, value } = e;

        const indexFound = formData.findIndex((element: any) => element.controlName === name)
        let tempFormData = [...formData];

        if (indexFound !== -1) {
            tempFormData[indexFound].values = value
        } else {
            tempFormData.push({
                "controlName": name,
                "values": value
            })
        }
        setFormData(tempFormData);
    };

    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any) => {
        if (items.visible === false) {
            return;
        }
        let itemsToRender = [];
        let columnsClassess =  "col-12  pb-4";
        
        switch (items.type) {
            
            case "text":
                itemsToRender.push(
                    <div hidden={!items.visible} className="column">
                        <TextField
                            key={"text_field" + index}
                            label={items.label}
                            labelExternal={true}
                            name={items.name}
                            placeholder={items.placeholder}
                            disabled={!items.isEditable}
                            defaultSelected={formData.find((item: any) => item.controlName === items.name)?.values || ''}
                            onChange={(e:any)=>{ }}
                        />
                    </div>
                );
                break;
            case "selectpicker":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <EngagmentSelectPicker
                            isChild={[]}
                            formData={formData}
                            // value={props.data[items.name] || ''}
                            value={formData.find((item: any) => item.controlName === items.name)?.values || ''}
                            
                            token={props.token}
                            dspId={props.dspId}
                            items={items}
                            name={items.name}
                            handleChange={handleDropdownChange}
                        />
                    </div>
                );
                break;

            default:
                return null;
        }

        return itemsToRender;
        
    };

    // function to render the fields for the popup of passcode validation
    const __renderCaseEditDetails = () => {

        return (
            <div className="passcode-form-wrap p-2">
                <form onSubmit={(e) => __submitCaseDetails(e)} className="information-governance-container">
                    <div className="p-3">
                    <div className="row">
                        {formControls !== null ? formControls.controls.map((items: any, index: any) => {
                            return __renderLayout(items, index);
                        }) : null}
                    </div>
                    <div >
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                    </div>
                </form>
            </div>
        );
    };


    return (<div className="edit-case">
        <Popup
            header={__renderCaseEditDetailsTitle()}
            content={__renderCaseEditDetails()}
            footer={null}
        />
    </div>)
}

export default EditCasePopup;