// Importing React hooks and components
import { useEffect, useState } from 'react';
import EngagmentTrackerGrid from "../../case-summary/components/EngagmentTrackerGrid";

/**
 * Tabs component to display tabular content based on caseData.
 *
 * @param {any} props - Data received from a parent component.
 * @returns {JSX.Element} - Rendered component.
 */
const Tabs: any = (props: any) => {
    // State for active tab and case data
    const [activeTab, setActiveTab] = useState<number>(props.activeTab)

    /**
     * Event handler for tab click. Sets active tab and fetches corresponding case data.
     *
     * @param {string} id - ID of the clicked tab.
     */
    const _handleTabClick = (id: number): void => {
        setActiveTab(id);
        props.onClickTab(id)
    }

    /**
    * Helper function to render the tabs header.
    *
    * @param {any} data - Data to populate the tabs.
    * @returns {JSX.Element} - Rendered JSX for the tab headers.
    */
    const __renderTabHeader = (data: any) => {
        return <div className='tabs-header'>
            <ul className="nav nav-tabs">
                {data.map((res: any, index: number) => {
                    let customStyle = {};
                    let isActiveTab = activeTab === index;
                    
                    if (res.tableHeaderColour !== "") {
                        if (isActiveTab) {
                            customStyle = res.tableHeaderColour !== "" ? { backgroundColor: res.tableHeaderColour, color: res.tableHeaderFontColour } : {}
                        } else {
                            customStyle = res.tableHeaderColour !== "" ? { color: res.tableHeaderColour, backgroundColor: res.tableHeaderFontColour } : {}
                        }
                    }

                    return <li className={isActiveTab ? "nav-item active" : "nav-item"} key={res.tabId}>
                        <button onClick={() => _handleTabClick(index)} className={"nav-link " + isActiveTab ? "active" : ""} aria-current="page" style={customStyle} >{res.title || "Case Summary"}</button>
                    </li>
                })}</ul>
        </div>
    }



    const __renderTabContainer = (data: any) => {
        const activeTabContent = props.tabs[activeTab];
        

        let renderHTMLData = null;

        if (activeTabContent.type === "casesummary") {
            renderHTMLData = props.caseSummary;
        } else if (activeTabContent.type === "datagrid") {
            renderHTMLData = <EngagmentTrackerGrid
                selectedCaseReference={props.overviewReferenceId}
                token={props.token}
                dsp={props.dsp}
                gridId={props.gridId}
                tabs={activeTabContent}
                controlId={activeTabContent ? activeTabContent.datagrid.dataGridId : ''}
            />
        } else if (activeTabContent.type === "listview") {
            renderHTMLData = <div>Content Type Listview Not available</div>
        }

        return <div className='tabs-container'><div>{renderHTMLData}</div></div>
    }


    // Main component render
    return (
        <div className='pb-4'>
            {__renderTabHeader(props.tabs)}

            <div className="tab-content">
                {__renderTabContainer(props.tabs)}
            </div>
        </div>
    )
}

// Export the Tabs component
export default Tabs;