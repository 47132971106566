import { combineReducers } from '@reduxjs/toolkit';

// import all common reducers
import uiStateReducers from './ui-state-reducers';


// import all the reducers from the application
import caseSummaryConfigReducer from '../../views/case-summary-one-view/store/reducers/config-reducers'
import caseSummaryDataReducer from '../../views/case-summary-one-view/store/reducers/summary-data-reducers'
import caseDataReducers from '../../views/case-summary-one-view/store/reducers/case-data-reducers';
import informationGovernanceReducers from '../../views/case-summary-one-view/store/reducers/information-governance-reducers';
import igapiReducers from '../../views/case-summary-one-view/store/reducers/igapi-reducers';
import overviewreferenceReducer from '../../views/case-summary-one-view/store/reducers/fetch-overview-reference-reducers';
import overviewreferenceaccessReducer from '../../views/case-summary-one-view/store/reducers/fetch-overview-reference-access-reducers';

// import all the reducers from the application
import caseSummaryConfigReducerCM from '../../views/case-summary/store/reducers/config-reducers'
import caseSummaryDataReducerCM from '../../views/case-summary/store/reducers/summary-data-reducers'
import caseDataReducersCM from '../../views/case-summary/store/reducers/case-data-reducers';
import informationGovernanceReducersCM from '../../views/case-summary/store/reducers/information-governance-reducers';
import igapiReducersCM from '../../views/case-summary/store/reducers/igapi-reducers';
import overviewreferenceReducerCM from '../../views/case-summary/store/reducers/fetch-overview-reference-reducers';
import overviewreferenceaccessReducerCM from '../../views/case-summary/store/reducers/fetch-overview-reference-access-reducers';
import caseDetailAllReducerCM from '../../views/case-summary/store/reducers/case-detail-all-reducer';
import addEditReducerCM from '../../views/case-summary/store/reducers/add-edit-data-reducer';
import selectPickReducerCM from '../../views/case-summary/store/reducers/pick-list-data-reducer';
import fetchAllEngagmentTrackerReducerCM from '../../views/case-summary/store/reducers/fetch-all-engagment-traker-reducer';
import addEditEngagmentReducerCM from '../../views/case-summary/store/reducers/add-edit-engagment-data-reducer';
import addEngagmentReducerCM from '../../views/case-summary/store/reducers/add-engagment-reducer';
import getEditditEngagmentReducerCM from '../../views/case-summary/store/reducers/get-edit-engagment-reducer';
import editEngagmentDataReducerCM from '../../views/case-summary/store/reducers/edit-engagment-reducer';
import deleteEngagmentDetailDataReducerCM from '../../views/case-summary/store/reducers/delete-engagment-reducer';
import selectEngagmentPickReducerCM from '../../views/case-summary/store/reducers/pick-list-engagment-data-reducer';
import HHCReducerCM from '../../views/case-summary/store/reducers/get-hhc-reducer';
import emmitIframeEventsReducerCM from '../../views/case-summary/store/reducers/emmit-iframe-events-reducer';
import printDocSlice from '../../views/case-summary/store/print-document.slice';
import deleteDocSlice from '../../views/case-summary/store/delete-document.slice';
import fetchAllDocsSlice from '../../views/case-summary/store/fetch-all-document.slice';



import communityAssetsReducer from '../../views/community-assets/store/reducers/community-assets-reducer'
import communityAssetsConfigReducer from '../../views/community-assets/store/reducers/community-asstes-config-reducer'
import communityAssetsListReducer from '../../views/community-assets/store/reducers/community-assets-list-reducer'
import makeShiftReducer from '../../views/community-assets/store/reducers/make-shift-reducer'

import loginReducers from '../../views/auth-msal/store/reducers/login-reducers';
import passcodeReducers from '../../views/auth-msal/store/reducers/passcode-reducer';

import resetPasswordReducer from '../../views/auth-msal/store/reducers/resetpassword-reducer';
import resetPasscodeReducer from '../../views/auth-msal/store/reducers/resetpasscode-reducer';


import dspCodesReducers from "../../views/dashboard/store/reducers/get-dsp"
import caseManagementConfigReducer from "../../views/dashboard/store/reducers/case-management-config"
import getPickerListByIdReducer from "../../views/dashboard/store/reducers/pick-list"
import mainGridDataReducer from '../../views/dashboard/store/reducers/main-grid-data';
import childGridDataReducer from '../../views/dashboard/store/reducers/child-grid-data';
import editCaseDetailsReducer from '../../views/dashboard/store/reducers/edit-case-data-by-id';
import searchCaseReducer from '../../views/dashboard/store/reducers/search-case-data';
import addNewCaseReducer from '../../views/dashboard/store/reducers/add-new-case-data';
import deleteRecordByIdReducer from '../../views/dashboard/store/reducers/delete-data-by-id';
import bulkConfigActionSliceReducer from '../../views/dashboard/store/bulkactionconfig.slice';
import bulkActionSliceReducer from '../../views/dashboard/store/bulkaction.slice';
import bulkDeleteActionSliceReducer from '../../views/dashboard/store/bulkdeleteaction.slice';
import getPickerListAllReducer from '../../views/dashboard/store/fetchAllPickerList.slice';


import feedbackCsColumnsReducers from '../../views/case-summary/store/reducers/feedback-cs-columns-reducers';
import feedbackCsReducers from '../../views/case-summary/store/reducers/feedback-cs-reducers'
import feedbackCsEditReducers from '../../views/case-summary/store/reducers/feedback-cs-edit-reducers';
import feedbackCsDeleteReducers from '../../views/case-summary/store/reducers/feedback-cs-delete-reducers';
import feedbackCsAddReducers from '../../views/case-summary/store/reducers/feedback-cs-add-reducers';
import iframeConfigDataReducers from '../../views/case-summary/store/reducers/iframe-data-reducer';

import downloadAssetsReducer from '../../views/case-summary/store/reducers/download-assets-reducers';
import createCaseDataReducer from '../../views/dashboard/store/reducers/create-case-data';


// combine all reducers together
const appReducer = combineReducers({
    uiStates: uiStateReducers,
    caseSummaryConfig: caseSummaryConfigReducer,
    caseSummaryData: caseSummaryDataReducer,
    feedbackCsColumns: feedbackCsColumnsReducers,
    feedbackCs: feedbackCsReducers,
    feedbackCsAdd: feedbackCsAddReducers,
    feedbackCsEdit: feedbackCsEditReducers,
    feedbackCsDelete: feedbackCsDeleteReducers,
    caseData: caseDataReducers,
    informationGovernanceData: informationGovernanceReducers,
    igapiData: igapiReducers,
    overviewreference: overviewreferenceReducer,
    overviewreferenceaccess: overviewreferenceaccessReducer,
    editCaseDetails: editCaseDetailsReducer,

    // Case Management Summary View
    caseSummaryConfigCM: caseSummaryConfigReducerCM,
    caseSummaryDataCM: caseSummaryDataReducerCM,
    caseDataCM: caseDataReducersCM,
    informationGovernanceDataCM: informationGovernanceReducersCM,
    igapiDataCM: igapiReducersCM,
    overviewreferenceCM: overviewreferenceReducerCM,
    overviewreferenceaccessCM: overviewreferenceaccessReducerCM,
    caseDetailAllCM:caseDetailAllReducerCM,
    addEditCM: addEditReducerCM,
    selectPickCM: selectPickReducerCM,
    fetchAllEngagmentTrackerCM: fetchAllEngagmentTrackerReducerCM,
    addEditEngagmentCM: addEditEngagmentReducerCM,
    addEngagmentCM: addEngagmentReducerCM,
    getEditEngagmentCM: getEditditEngagmentReducerCM,
    editEngagmentDataCM : editEngagmentDataReducerCM,
    deleteEngagmentDetailDataCM : deleteEngagmentDetailDataReducerCM,
    selectEngagmentPickCM: selectEngagmentPickReducerCM,
    HHCAlert: HHCReducerCM,
    emmitIframeEvents: emmitIframeEventsReducerCM,
    
    loginData: loginReducers,
    passcodeData: passcodeReducers,
    dspCodes: dspCodesReducers,
    caseManagementConfig: caseManagementConfigReducer,
    getPickerListById: getPickerListByIdReducer,
    mainGridData: mainGridDataReducer,
    childGridData: childGridDataReducer,
    deleteRecordById: deleteRecordByIdReducer,
    bulkConfigActionSliceData: bulkConfigActionSliceReducer, 
    bulkActionSliceData: bulkActionSliceReducer, 
    bulkDeleteActionData: bulkDeleteActionSliceReducer,
    getPickerListAllData: getPickerListAllReducer,
    resetPassword: resetPasswordReducer,
    resetPasscode: resetPasscodeReducer,

    communityAssets: communityAssetsReducer,
    communityAssetsConfig: communityAssetsConfigReducer,
    communityAssetsList: communityAssetsListReducer,
    makeShift: makeShiftReducer,
    downloadAssets: downloadAssetsReducer,
    createCaseData: createCaseDataReducer,
    iframeConfigData: iframeConfigDataReducers,
    searchCaseData: searchCaseReducer,
    addNewCaseData: addNewCaseReducer,
    printDocData: printDocSlice,
    deleteDocData: deleteDocSlice,
    fetchAllDocData: fetchAllDocsSlice,
});


const rootReducer = (state: any, action: any) => {
    // if (action.type === 'IS_LOGOUT') {
    //     state = undefined
    // }
    return appReducer(state, action)
}
export default rootReducer;