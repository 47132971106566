import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface NotificationPopupProps {
  show: boolean;
  message: string;
  onClose: () => void;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ show, message, onClose }) => {
    
 
return (
   
 <Modal show={show} onHide={onClose}>
  <Modal.Header closeButton style={{ backgroundColor: '#3F4C89', color: 'white' }}>
    <Modal.Title>Notification</Modal.Title>
  </Modal.Header>
  <Modal.Body>{message}</Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={onClose}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
        

  );
};

export default NotificationPopup;
